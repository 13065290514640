
import Slider from '@/components/page_blocks/Slider'
import NewYearAlert from '@/components/NewYearAlert'

export default {
  name: 'MainPage',
  scrollToTop: true,
  layout: 'main_page/Index',
  middleware: 'mobile',
  components: {
    NewYearAlert,
    Slider
  },
  computed: {
    isIe () {
      return this.$ua.browser() === 'Internet Explorer'
    }
  },
  head() {
    return {
      title: 'Евроген',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Наборы и сервисы для молекулярной и клеточной биологии'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Евроген, Евроген главная, Евроген сайт, evrogen'
        }
      ]
    }
  }
}
